import React from 'react'
import Layout from '../components/Layout'
import SocialLinks from '../components/SocialLinks'
import Seo from "../components/Seo"
import * as S from '../components/Post/styled'
import styled from 'styled-components'

const AboutWrapper = styled.div`
  color: white;
  height: 100%;
  max-width: 60rem;
  margin: 0 auto;
`

const AboutTitle = styled(S.PostTitle)`
  font-size: 3rem;
  max-width: 60rem;
`

const AboutPage = () => (
  <Layout showSocialLinks={false}>
    <Seo title="Sobre" />
    <AboutWrapper>
      <S.PostHeader>
        <AboutTitle>
          Olá!
        </AboutTitle>
      </S.PostHeader>
      <S.MainContent>
        <p>
          Tenho 24 anos, sou formado em Engenharia da Computação pela FIAP e trabalho como Senior Software Engineer na X-Team. 
        </p>
        <p>
          Já trabalhei e prestei serviço para outras empresas como IBM, Midway, Z1, Grupo Pão de Açúcar, Banco Votorantim e Marketdata.
        </p>
        <p>
          Tenho interesse por filosofia e economia. Ter novas ideias,
          conhecer novas pessoas e explorar o mundo é algo que me fascina.
        </p>
        <p>
          Caso queira conversar minhas redes sociais estão abaixo. Até mais :D
        </p>
      </S.MainContent>
      <SocialLinks />
    </AboutWrapper>
    
  </Layout>
)

export default AboutPage;
